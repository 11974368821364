<div class="handset-container" *ngIf="isHandset$ | async; else webview">
    <div class="header">
        <a class="logo" [routerLink]="['/']">
            <img loading="lazy" src="./../../../assets/icons/main-logo.svg"
                alt="Buy Makeup Product Online | Foreveryng">
        </a>
        <a class="title" [routerLink]="['/']">
            <span>FOREVERYNG</span>
        </a>
        <div class="nav-list">
            <a class="nav" [routerLink]="['/cart']">
                <div class="circle">
                    <span [matBadge]="cartCount$ | async" matBadgeSize="small" matBadgeColor="accent"
                        matBadgeOverlap="false" [matBadgeHidden]="(isLoggedIn$ | async) === 'loggedIn'">
                        <img loading="lazy" src="../../../../assets/icons/cart-bag.svg"
                            alt="Buy Makeup Products Online | Foreveryng">
                    </span>
                </div>
            </a>
        </div>
    </div>
    <div class="search">
        <div class="search-bar" tabindex="0" (click)="viewAutoSearch()" (keypress)="viewAutoSearch()">
            <span class="text">Search products and brands</span>
            <img loading="lazy" src="../../../../assets/icons/search-icon.svg"
                alt="Buy Makeup Product Online | Foreveryng">
        </div>
        <div class="autosearch_sectionscntainer" *ngIf="viewAutoSearch$ | async">
            <app-auto-search [suggestionsData]="suggestionsData"></app-auto-search>
        </div>
        <div class="overlay" *ngIf="viewAutoSearch$ | async" tabindex="0" (click)="closeAutoSearch()"
            (keypress)="closeAutoSearch()">
        </div>
    </div>
</div>

<ng-template #webview>
<header class="web-container">
    <div class="logosecs_header">
        <div class="first-navbar">
            <h1 class="logo">
                <a [routerLink]="['/']" title="Foreveryng" aria-label="Home">
                    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.218018" y="0.286621" width="44" height="43.4786" rx="21.7393" fill="#DD346C" />
                        <path
                            d="M11.7262 15.3031H19.1765L22.2034 11.0781H7.25598V32.9737H11.7262V24.3868H20.028L17.1898 20.3417H11.7262V15.3031Z"
                            fill="white" />
                        <path
                            d="M27.1816 20.5852C23.9043 16.5412 22.3926 12.5258 21.9991 11.0781L18.9189 15.3166C20.4187 19.0189 23.5247 23.1721 24.8903 24.7859L25.0291 32.9737H29.4035V24.7859C33.6806 19.7166 36.37 13.6078 37.1801 11.1871H32.3891C30.3339 16.5981 28.0611 19.7071 27.1816 20.5852Z"
                            fill="white" />
                    </svg>
                    <span>Forever<strong>yng</strong></span>
                </a>
            </h1>
            <div class="search">
                <input type="search" name="search" id="search" class="search-bar"
                    placeholder="Search products and brands" (click)="viewAutoSearch()" (keypress)="viewAutoSearch()">
                <div class="autosearch_sectionscntainer" *ngIf="viewAutoSearch$ | async">
                    <app-auto-search [suggestionsData]="suggestionsData"></app-auto-search>
                </div>
                <div class="overlay" *ngIf="viewAutoSearch$ | async" tabindex="0" (click)="closeAutoSearch()"
                    (keypress)="closeAutoSearch()"></div>
            </div>
            <div class="actions">
                <div class="account">
                    <div class="hello_signin">
                        <strong>Hello,
                            <span *ngIf="(isLoggedIn$ | async) !== 'loggedIn'">Sign in</span>
                            <span *ngIf="isLoggedIn$ | async">
                                <ng-container *ngIf="userData$ | async as userData">
                                    {{ userData.user.name.split(' ')[0] | titlecase }}
                                </ng-container>
                            </span>
                        </strong>
                        <a [routerLink]="['/profile']">
                            <h2>Accounts & Loyalty</h2>
                        </a>
                    </div>
                    <div class="nabar_accountsecs">
                        <div class="login_dropdown_sec" *ngIf="(isLoggedIn$ | async) !== 'loggedIn'">
                            <a [routerLink]="['/auth']">Sign In</a>
                            <span>New To Foreveryng <a [routerLink]="['/auth/register']">Register Here</a></span>
                        </div>
                        <ng-container *ngIf="(isLoggedIn$ | async) && (userData$ | async) as userData">
                            <div class="login_dropdown_sec_afterlogin">
                                <div class="userdetails_cnts">
                                    <div class="usericon">
                                        <img loading="lazy" *ngIf="userData.user.avatar" [src]="userData.user.avatar"
                                            alt="Buy Makeup Products Online | Foreveryng">
                                        <img src="../../../../assets/images/avatar-placeholder.jpg"
                                            *ngIf="!userData.user.avatar" alt="">
                                    </div>
                                    <div class="userdetails">
                                        <h2>{{userData.user.name}}</h2>
                                        <span>{{userData.user.email}}</span>
                                    </div>
                                </div>
                                <div class="usermanage_prflssec">
                                    <a [routerLink]="['/profile']">Manage Profile <mat-icon>chevron_right</mat-icon></a>
                                </div>
                            </div>
                        </ng-container>
                        <div class="nav_list_logins">
                            <!-- <ng-container *ngIf="userData$ | async as userData">
                                <div class="claim-daily-reward" *ngIf="userData.dailyVisitRewardPopUp.showPopUp">
                                    <div class="claim-text">{{ userData.dailyVisitRewardPopUp.body }}</div>
                                    <button class="claim-btn" (click)="claimDailyReward()">Claim Reward</button>
                                </div>
                            </ng-container> -->
                            <div class="block_wrap_listlogin">
                                <h2>Your Account</h2>
                                <a [routerLink]="['/profile']">Your Profile</a>
                                <a [routerLink]="['/orders']">Orders</a>
                                <a [routerLink]="['/profile/wishlist']">Wishlists</a>
                                <a [routerLink]="['/reviews']">Reviews</a>
                                <span *ngIf="isLoggedIn$ | async" tabindex="0" (click)="logOutUser()"
                                    (keypress)="logOutUser()">Log Out</span>

                            </div>

                            <div class="block_wrap_listlogin">
                                <h2>Your Lists</h2>
                                <a [routerLink]="['/profile/subscriptions']">Subscriptions</a>
                                <!-- <li><a [routerLink]="['/influencer']">Influncer Collabaration</a></li> -->
                                <a [routerLink]="['/profile/private-coupons']">Your Coupons</a>
                                <a [routerLink]="['/profile/verify-number']">Verify Number</a>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="nav-list">
                    <div class="nav" [routerLink]="['/profile/wishlist']">
                        <span [matBadge]="wishlistCount$ | async" matBadgeSize="small" matBadgeColor="accent"
                            [matBadgeHidden]="(isLoggedIn$ | async) === 'loggedIn'">
                            <svg width="32" height="29" viewBox="0 0 26 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M17.3184 2.85H17.3182C15.5326 2.85 13.9679 3.70507 13 5.14772C12.0321 3.70507 10.4674 2.85 8.68182 2.85L8.68162 2.85C7.26283 2.85183 5.90616 3.47753 4.90811 4.58384C3.91052 5.68964 3.35164 7.1859 3.35 8.74377V8.74393C3.35 11.5979 4.95432 14.5439 8.06904 17.4983L8.06927 17.4985C9.49712 18.8472 11.0397 20.0396 12.6754 21.0589L12.678 21.0605C12.776 21.1188 12.8867 21.15 13 21.15C13.1133 21.15 13.224 21.1188 13.322 21.0605L13.322 21.0605L13.3246 21.0589C14.9603 20.0396 16.5029 18.8472 17.9307 17.4985L17.931 17.4983C21.0457 14.5439 22.65 11.5979 22.65 8.74393V8.74377C22.6484 7.1859 22.0895 5.68964 21.0919 4.58384C20.0938 3.47753 18.7372 2.85183 17.3184 2.85ZM12.38 6.9071L12.38 6.90725C12.4286 7.03822 12.512 7.15266 12.622 7.2344C12.7323 7.31632 12.8638 7.36127 13 7.36127C13.1362 7.36127 13.2677 7.31632 13.378 7.2344C13.488 7.15266 13.5714 7.03822 13.62 6.90725L13.62 6.9071C14.2154 5.29519 15.6308 4.29881 17.3181 4.29879C18.3722 4.30003 19.3863 4.7646 20.1368 5.59655C20.8878 6.42903 21.3125 7.56078 21.3136 8.74407C21.3136 11.349 19.5891 13.8402 17.6562 15.8091C15.7977 17.7021 13.7739 19.0849 13 19.5853C12.2261 19.0849 10.2023 17.7021 8.34376 15.8091C6.41085 13.8402 4.68642 11.349 4.68636 8.74407C4.68747 7.56078 5.11216 6.42903 5.86318 5.59655C6.61371 4.7646 7.62783 4.30003 8.68189 4.29879C10.3692 4.29881 11.7846 5.29519 12.38 6.9071Z"
                                    fill="#383838" stroke="#383838" stroke-width="0.1" />
                            </svg>
                        </span>
                    </div>
                    <div class="nav nav-cart">
                        <div class="circle" tabindex="0" (click)="viewMiniCart = true" (keypress)="viewMiniCart = true">
                            <span [matBadge]="cartCount$ | async" matBadgeSize="small" matBadgeColor="accent"
                                [matBadgeHidden]="(isLoggedIn$ | async) === 'loggedIn'" matBadgeOverlap="false">
                                <img loading="lazy" src="../../../../assets/icons/cart-bag.svg"
                                    alt="Buy Makeup Products Online | Foreveryng">
                            </span>
                        </div>
                        <ng-container *ngIf="(isLoggedIn$ | async)">
                            <div class="mini-cart" [@animateCart]="viewMiniCart ? 'open' : 'close'">
                                <div class="cart-items">
                                    <h2>Cart Totals <span tabindex="0" (click)="viewMiniCart = false"
                                            (keypress)="viewMiniCart = false"><img loading="lazy"
                                                src="../../../../assets/icons/popupclose-icon.svg"
                                                alt="Buy Makeup Products Online | Foreveryng"></span></h2>
                                    <div class="cart-item" *ngFor="let item of (cartData$ | async)?.cartItems">
                                        <div class="minicart_imgsec">
                                            <img loading="lazy" [src]="item.attributeProduct.images[0]"
                                                alt="Buy Makeup Products Online | Foreveryng" />
                                        </div>
                                        <div class="mini_cart_descriptions">
                                            <h3>{{item.name}}</h3>
                                            <div class="price_attminicartsec">
                                                <div class="price_minicart">
                                                    Price: {{item.attributeProduct.discountedPrice*item.quantity |
                                                    nepaliCurrency}}
                                                </div>
                                                <div class="attribute" *ngIf="item.attributeProduct.type !== 'none'">
                                                    {{item.attributeProduct.type === 'color' ? 'Shade' :
                                                    item.attributeProduct.type}}:
                                                    <span *ngIf="item.attributeProduct.type === 'color'"
                                                        [ngStyle]="{ 'background-color' : item.attributeProduct.code }"
                                                        class="attribute-color">
                                                    </span>
                                                    <img loading="lazy" [src]="item.attributeProduct.code"
                                                        [alt]="item.attributeProduct.code"
                                                        *ngIf="item.attributeProduct.type === 'image'">
                                                    <span class="attribute-name">
                                                        {{item.attributeProduct.name}}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="cartitem_qty">
                                                Quantity: <span tabindex="0"
                                                    (click)="adjustCartItemQuantity(item.id, item.quantity-1)"
                                                    (keypress)="adjustCartItemQuantity(item.id, item.quantity-1)"
                                                    class="btn">
                                                    -
                                                </span>
                                                <span>{{item.quantity}}</span>
                                                <span class="btn" tabindex="0"
                                                    (keypress)="adjustCartItemQuantity(item.id, item.quantity+1)"
                                                    (click)="adjustCartItemQuantity(item.id, item.quantity+1)">
                                                    +
                                                </span>
                                            </div>

                                            <div class="express-delivery-info"
                                                *ngIf="item.quickDelivery.isQuickDeliverable">
                                                <img [src]="item.quickDelivery.quickDeliverableIcon" alt=""
                                                    height="16px">
                                                {{ item.quickDelivery.productQuickDeliverableMessage }}
                                            </div>

                                            <div class="remove_cartitems_sec" tabindex="0"
                                                (click)="removeFromCart(item.id)" (keypress)="removeFromCart(item.id)">
                                                <img loading="lazy" src="../../../../assets/icons/icon-delete.svg"
                                                    alt="remove from cart icon">
                                            </div>
                                        </div>
                                        <div class="cart_updates_loader" *ngIf="cartUpdating[item.id]">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cartlinks_bottom">
                                    <a [routerLink]="['/cart']" tabindex="0" (click)="viewMiniCart = false"
                                        (keypress)="viewMiniCart = false">Proceed To Cart</a>
                                </div>
                            </div>
                            <div class="overlay" tabindex="0" (click)="viewMiniCart = false"
                                (keypress)="viewMiniCart = false" *ngIf="viewMiniCart"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="topaddbanner_header">
        <img src="../../../../assets/images/header-banner.gif" alt="">
    </div>
    <div class="catlist_header" (mouseleave)="closeSubCatalog()">
        <div class="second-navbar">
            <div class="first-line">
                <div class="nav-list">
                    <div class="nav" (focus)="showSubCatalog(0, 'brand')" (mouseover)="showSubCatalog(0, 'brand')">
                        Brands
                    </div>
                    <div class="nav" *ngFor="let item of (navItems$ | async)?.slice(0, 11)"
                        (focus)="showSubCatalog(item.id)" (mouseover)="showSubCatalog(item.id)" tabindex="0"
                        (click)="handleClick(item)" (keypress)="handleClick(item)">
                        {{item.title}}
                    </div>
                </div>
                <div class="offers" *ngIf="offersRedirectData$ | async as offer">
                    <a [routerLink]="['/campaign', offer.id+'-'+offer.title.split(' ')[0].toLowerCase()]"
                        *ngIf="offer.title">{{ offer.title }}</a>
                </div>
            </div>
            <div class="sub-catalog" *ngIf="subCatalogData.display" @dropdownCatalog>
                <ng-container *ngIf="subCatalogData.type === 'category'">
                    <div class="image">
                        <img loading="lazy" [src]="subCatalogData.data?.image"
                            alt="Buy Makeup Product Online | Foreveryng">
                        <span class="title">{{subCatalogData.data?.title}}</span>
                        <a [routerLink]="['/category', subCatalogData.data.slug, subCatalogData.data.id]">
                            <span class="explore-link" tabindex="0" (click)="handleClick(subCatalogData.data)"
                                (keypress)="handleClick(subCatalogData.data)">
                                Explore All
                            </span>
                        </a>
                    </div>
                    <div class="sub-data">
                        <div class="child" *ngFor="let child of subCatalogData.data?.children">
                            <div class="child-title" tabindex="0" (click)="handleClick(child)"
                                (keypress)="handleClick(child)">{{child.title}}</div>
                            <div class="grandChildren">
                                <div class="grandChild" *ngFor="let gc of child.children" tabindex="0"
                                    (click)="handleClick(gc)" (keypress)="handleClick(gc)">
                                    {{gc.title}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="subCatalogData.type === 'brand'">
                    <div class="brands-list">
                        <div class="brand" *ngFor="let brand of subCatalogData.data.brands" tabindex="0"
                            (click)="handleClick(brand)" (keypress)="handleClick(brand)">
                            <span class="brand-title">{{brand.title}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</header>
</ng-template>
